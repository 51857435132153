import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import { withShopifyContext } from 'src/state/ShopifyState'
import Helmet from 'react-helmet'

import { fbTrack } from 'src/utils/tracking'

import SEO from 'src/components/SEO'
import ComponentRenderer from 'src/components/ComponentRenderer'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import ProductAboveTheFold from 'src/components/ProductAboveTheFold'
import Drawer from 'src/components/Drawer'
import MobileMenu from 'src/components/MobileMenu'
import ResponsiveComponent from 'src/components/ResponsiveComponent'

const propTypes = {
  data: PropTypes.object.isRequired,
}

const BLACKLIST = ['31', '32']

class ProductTemplate extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      product: null,
      variant: null,
      allColorways: null
    }
  }

  componentDidMount () {
    const { pageContext = {}, shopifyContext = {} } = this.props
    const { id } = pageContext
    const { fetchProductById } = shopifyContext
    fetchProductById({ productId: id })
      .then(product => {
        this.setState({ product })

        // Facebook Tracking
        const contentId = product.id.split('gid://shopify/Product/')[1]

        // fbTrack('track', 'ViewContent', {
        //   currency: 'USD',
        //   content_type: 'product_group',
        //   item_group_id: contentId,
        //   content_ids: this.state.variant
        // })

        // Klaviyo Tracking
        const _learnq = _learnq || []
        const klaviyoItem = {
          Name: product.title,
          ProductID: product.id,
          Categories: [product.productType],
          Price: product.variants && product.variants.length > 0 && product.variants[0].price.amount,
          URL: `${ process.env.GATSBY_HOST }/shop/${ product.handle }`,
          CompareAtPrice: product.variants && product.variants.length > 0 && product.variants[0].compareAtPrice
        }
        _learnq.push(['track', 'Viewed Product', klaviyoItem])
        _learnq.push(['trackViewedItem', {
          Title: klaviyoItem.Name,
          ItemId: klaviyoItem.ProductID,
          Categories: klaviyoItem.Categories,
          Url: klaviyoItem.URL,
          Metadata: {
            Price: klaviyoItem.Price,
            CompareAtPrice: klaviyoItem.CompareAtPrice
          }
        }])
      })
  }

  setVariant = newVariant => {
    const productId = this.state.product.id.split('gid://shopify/Product/')[1]
    const variantId = newVariant.id.split('gid://shopify/ProductVariant/')[1]
    this.setState({ variant: variantId })
    fbTrack('track', 'ViewContent', {
      currency: 'USD',
      content_type: 'product_group',
      item_group_id: productId,
      content_ids: variantId
    })
  }

  findColorSwatch = ({ apparelMagicProduct = {}, apparelMagicProductAttributes = [] }) => {
    const billOfMaterials = apparelMagicProduct.bill_of_materials && apparelMagicProduct.bill_of_materials
    const swatches = {}
    billOfMaterials && billOfMaterials.forEach(material => {
      apparelMagicProductAttributes.forEach(attribute => {
        if ((material.product_id === attribute.product_id) && attribute.image) {
          if (isNaN(attribute.attribute_2)) {
            // color styles have letters and button,clip,etc styles have numbers
            if (!BLACKLIST.includes(material.product_id)) swatches[attribute.id] = attribute
          }
        }
      })
    })
    return Object.values(swatches).filter(x => x.image)
  }

  findMaterialDescription = ({ allMaterials, swatches }) => {
    if (!allMaterials || !swatches) return null
    const materialObject = swatches && swatches[0] && allMaterials && allMaterials.find(x => x.product_id === swatches[0].product_id)
    if (!materialObject) return null
    return materialObject.description
  }

  render () {
    const { pageContext = {}, shopifyContext = {} } = this.props
    const { id, webTitle, handle } = pageContext
    const site = this.props.data.allContentfulSiteSettings && this.props.data.allContentfulSiteSettings.edges.filter(edge => !edge.node.internalName.includes('PLACEHOLDER'))[0].node
    const { product } = this.state
    let page = handle && this.props.data && this.props.data.allContentfulProduct.edges[0] && this.props.data.allContentfulProduct.edges
    .find(x => x?.node?.shopifyProductHandle === handle)

    if (!page) {
      return false
    }

    const { sizeChart, sizeFitText, status } = page?.node
    page = page && page?.node
    let apparelMagicProduct = this.props.data && this.props.data.allApparelMagicProduct.edges[0] && this.props.data.allApparelMagicProduct.edges
      .find(x => x.node.web_title === webTitle)
    apparelMagicProduct = apparelMagicProduct && apparelMagicProduct.node

    const productSettings = this.props.data.allContentfulProductPageSettings.edges[0].node

    const apparelMagicProductAttributes = this.props.data && this.props.data.allApparelMagicProductAttributes.edges.map(x => x.node)
    const allApparelMagicProducts = this.props.data && this.props.data.allApparelMagicProduct.edges.map(x => x.node)
    const allProductSections = productSettings && productSettings.sections
    const sections = page && page.sections
    const sizeGuideImage = page && page.sizeGuideImage
    const seo = page && page.seo

    const { dictionary } = shopifyContext
    const allColorways = product && dictionary[product.title]
    const possibleSwatches = this.findColorSwatch({ apparelMagicProduct, apparelMagicProductAttributes })
    let oneSwatchPerColorway = []
    if (possibleSwatches && allColorways) {
      allColorways.forEach(colorway => {
        let found = false
        possibleSwatches.find(swatch => {
          if (colorway?.variants?.edges[0]?.node?.selectedOptions?.map(x => x.value)?.includes(swatch.attr_2_name)) {
              swatch.handle = colorway.handle
              if (!found) oneSwatchPerColorway.push(swatch)
              found = true
            }
        })
      })
    }

    oneSwatchPerColorway = oneSwatchPerColorway.filter(x => x.handle)

    const allMaterials = allApparelMagicProducts && allApparelMagicProducts.filter(x => x.description && (x.is_product === '0'))
    const materialDescription = this.findMaterialDescription({ allMaterials, swatches: oneSwatchPerColorway })
    return (
      <Fragment >
        <SEO
          title={webTitle}
          description={seo && seo.description && seo.description.description}
          siteSettings={site}
          keywords={seo && seo.keywords}
          shareImage={seo && seo.shareImage && 'https:' + seo.shareImage.file.url}
        />
        {product && (
          <Helmet
            meta={[
              {
                name: 'product:brand',
                content: product.vendor
              },
              {
                name: 'product:availability',
                content: product.availableForSale ? 'in stock' : 'out of stock'
              },
              {
                name: 'product:condition',
                content: 'new'
              },
              {
                name: 'product:price:amount',
                content: product.variants && product.variants.length > 0 && product.variants[0].price.amount
              },
              {
                name: 'product:price:currency',
                content: 'USD'
              },
              {
                name: 'product:retailer_item_id',
                content: product.handle
              },
              {
                name: 'product:item_group_id',
                content: product.productType
              }
            ]}
          />
        )}
        <Header
          productPage={true}
          bannerText={site && site.bannerText}
          bannerColor={site && site.bannerColor}
          navigation={site && site.navigation}
          placeholder={false}
        />

        <Drawer />
        <ResponsiveComponent
          small={
            <MobileMenu
              navLinks={site && site.navigation}
            />
          }
          large={<span />}
        />

        {product && (
          <ProductAboveTheFold
            sizeGuideImage={sizeGuideImage}
            swatches={oneSwatchPerColorway}
            product={product}
            addToCartText={page.addToCartText}
            sizeChart={sizeChart}
            sizeFitText={sizeFitText}
            status={status}
            materialDescription={materialDescription}
            shippingText={productSettings && productSettings.shippingReturnsText}
            comingSoonModalHeadline={productSettings && productSettings.comingSoonHeadline}
            comingSoonModalText={productSettings && productSettings.comingSoonText}
            setVariant={this.setVariant}
          />
        )}

        {allProductSections && allProductSections.map((section, index) => {
          const prevTheme = ((index !== 0) && allProductSections[index - 1]) && allProductSections[index - 1].theme
          const prevFullWidth = ((index !== 0) && allProductSections[index - 1]) && allProductSections[index - 1].width === 'fullWidth'
          const nextTheme = ((index !== allProductSections.length - 1) && allProductSections[index + 1]) && allProductSections[index + 1].theme
          const nextFullWidth = ((index !== allProductSections.length - 1) && allProductSections[index + 1]) && allProductSections[index + 1].width === 'fullWidth'
          const lastSection = allProductSections.length === index + 1
          return (
            <ComponentRenderer
              prevTheme={prevFullWidth ? false : prevTheme}
              nextTheme={nextFullWidth ? false : nextTheme}
              isFirstSection={index === 0}
              isLastSection={lastSection}
              key={`${ section.id }_${ index }`}
              item={section}
              index={index}
            />
          )
        })}

        {sections && sections.map((section, index) => {
          const prevTheme = ((index !== 0) && sections[index - 1]) && sections[index - 1].theme
          const prevFullWidth = ((index !== 0) && sections[index - 1]) && sections[index - 1].width === 'fullWidth'
          const nextTheme = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].theme
          const nextFullWidth = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].width === 'fullWidth'
          const lastSection = sections.length === index + 1
          return (
            <ComponentRenderer
              prevTheme={prevFullWidth ? false : prevTheme}
              nextTheme={nextFullWidth ? false : nextTheme}
              isFirstSection={index === 0 && !(allProductSections && allProductSections.length > 1)}
              isLastSection={!productSettings.softFooter}
              key={`${ section.id }_${ index }`}
              item={section}
              index={index}
            />
          )
        })}

        {productSettings && productSettings.softFooter && productSettings.softFooter.map((section, index) => {
          const prevTheme = ((index !== 0) && sections[index - 1]) && sections[index - 1].theme
          const prevFullWidth = ((index !== 0) && sections[index - 1]) && sections[index - 1].width === 'fullWidth'
          const nextTheme = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].theme
          const nextFullWidth = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].width === 'fullWidth'
          return (
            <ComponentRenderer
              // prevTheme={prevFullWidth ? false : prevTheme}
              // nextTheme={nextFullWidth ? false : nextTheme}
              isFirstSection={false}
              isLastSection={true}
              key={`${ section.id }_${ index }`}
              item={section}
              index={index}
            />
          )
        })}

        <Footer {...site} footerTheme='red' />
      </Fragment>
    )
  }
}

ProductTemplate.propTypes = propTypes

// (filter: {internalName: {nin: "PLACEHOLDER Product"}})

export const productQuery = graphql`
  query {
		allContentfulSiteSettings {
	    edges {
	      node {
	        ...SiteSettings
	      }
	    }
	  }
    allContentfulProductPageSettings(filter: {internalName: {nin: "PLACEHOLDER Product Page Settings"}}) {
      edges {
        node {
          ...ProductPageSettings
        }
      }
    }
    allContentfulProduct {
      edges {
        node {
          id
          shopifyProductHandle
          sizeGuideImage {
            fixed(width: 1500) {
              ...GatsbyContentfulFixed_withWebp_noBase64
            }
          }
          sizeChart {
            json
          }
          sizeFitText {
            sizeFitText
          }
          status
          addToCartText
          sections {
            ...Columns
						...FiftyFifty
						...TextSection
						...WideMedia
            ...ProductGrid
            ...FeaturedCollection
            ...FabricsList
          }
          seo {
            description {
              description
            }
            keywords
            shareImage {
              file {
                url
              }
            }
          }
        }
      }
    }
    allApparelMagicProduct {
      edges {
        node {
          web_title
          id
          product_id
          description
          is_product
          bill_of_materials {
            product_id
            p_attr_2
          }
        }
      }
    }
    allApparelMagicProductAttributes {
      edges {
        node {
          id
          product_id
          attribute_2
          attr_2_name
          image {
            img
          }
        }
      }
    }
	}
`

export default withShopifyContext(ProductTemplate)
